import Vue from 'vue'
import axios from 'axios'

export function listaCientes() {
    return axios.get('/clientes.json').then(function (response) {
        return response.data;
    })
}

// Familias
export function getFamilias(sql = false) {
    if (sql) {
        return axios.get('/familiasm/combo.json').then(function (response) {
            return response.data;
        })
    } else {
        return axios.get('/familias/combo.json').then(function (response) {
            return response.data;
        })
    }
}

export function getFamiliaPorId(id, sql = false) {
    if (sql) {
        return axios.get('/familiasm/' + id + '.json').then(function (response) {
            return response.data;
        })
    } else {
        return axios.get('/familias/' + id + '.json').then(function (response) {
            return response.data;
        })
    }
}

export function getFamiliaPorCodigo(codigo, sql = false) {
    if (sql) {
        return axios.get('/familiasm/busca_codigo/' + codigo + '.json').then(function (response) {
            return response.data;
        })
    } else {
        return axios.get('/familias/busca_codigo/' + codigo + '.json').then(function (response) {
            return response.data;
        })
    }
}

// Entregas
export function listaEntregas(cliente, pendientes = 0, sql = false) {
    if (sql) {
        return axios.get('/entregasm/filtra_clientes.json?idcliente=' + cliente + '&pendientes=' + pendientes).then(function (response) {
            return response.data;
        })
    } else {
        return axios.get('/entregas/filtra_clientes.json?idcliente=' + cliente + '&pendientes=' + pendientes).then(function (response) {
            return response.data;
        })
    }
}

export function entregascliente(nombrecliente, importe, pendientes = 0, sql = false) {
    if (sql) {
        return axios.get('/entregasm/filtra_clientes.json?nombrecliente=' + nombrecliente + '&importe=' + importe + '&pendientes=' + pendientes).then(function (response) {
            return response.data;
        })
    } else {
        return axios.get('/entregas/filtra_clientes.json?idcliente=' + nombrecliente + '&importe=' + importe + '&pendientes=' + pendientes).then(function (response) {
            return response.data;
        })
    }
}

export function cuentaEntregas(cliente, pendientes = 0) {
    return axios.get('/entregas/filtra_clientes.json?idcliente=' + cliente + '&pendientes=' + pendientes).then(function (response) {
        return response.data;
    })
}

// Entregasm
export function entregaacuentacliente(cliente, importe, fecha) {
    return axios.post('entregasm/desdecaja.json', {
        cliente: cliente,
        importe: importe,
        fecha: fecha}
        ).then(function(response) {
            return response.data;
        })
}

export function entregasacuenta(pendientes = false) {
    if (pendientes) {
        return axios.get('entregasm/entregassincompensar.json').then(function(response) {
            return response.data;
        })
    } else {
        return axios.get('entregasm.json').then(function(response) {
            return response.data;
        })
    }
}

// Busqueda articulos
export function buscaarticuloscodigo(codigo, sql = false) {
    if (sql) {
        return axios.get('/articulosm/busca_codigo.json?filtro=' + codigo).then(function (response) {
            return response.data;
        })
    } else {
        return axios.get('/articulos/busca_codigo.json?filtro=' + codigo).then(function (response) {
            return response.data;
        })
    }
}

export function buscaarticulosdescripcion(descripcion, sql = false) {
    if (sql) {
        return axios.get('/articulosm/busca_descripcion.json?filtro=' + descripcion).then(function (response) {
            return response.data;
        })
    } else {
        return axios.get('/articulos/busca_descripcion.json?filtro=' + descripcion).then(function (response) {
            return response.data;
        })
    }
}

export function buscaarticulosmodelo(modelo, sql = false) {
    if (sql) {
        return axios.get('/articulosm/busca_modelo.json?filtro=' + modelo).then(function (response) {
            return response.data;
        })
    } else {
        return axios.get('/articulos/busca_modelo.json?filtro=' + modelo).then(function (response) {
            return response.data;
        })
    }
}

export function buscaarticulosfamilia(familia, sql = false) {
    if (sql) {
        return axios.get('/articulosm/busca_familia.json?filtro=' + familia).then(function (response) {
            return response.data;
        })
    } else {
        return axios.get('/articulos/busca_familia.json?filtro=' + familia).then(function (response) {
            return response.data;
        })
    }
}

export function getarticulosindex(codigo, familia) {
    return axios.get('/articulosm.json?numero=' + codigo + '&familia=' + familia).then(function (response) {
        return response.data;
    })
}

export function borraarticulo(codigo) {
    const token = document.querySelector('meta[name="csrf-token"]').content;

    return axios.delete('/articulosm/' + codigo + '.json', {headers: {'X-CSRF-TOKEN': token, 'Content-Type': 'application/json'}}).then(function (response) {
        return response.data;
    })
}

export function getarticulo(codigo) {
    return axios.get('/articulosm/' + codigo + '.json').then(function (response) {
        return response.data;
    })

}

export function actpreciosarticulo(codigo, pcostarifa, pcos, porcdesc1, porcdesc2, porcpvp, pvp) {
    const token = document.querySelector('meta[name="csrf-token"]').content;
    axios.defaults.headers.common['X-CSRF-Token'] = token;
    axios.defaults.headers.common['Accept'] = 'application/json';

    return axios.post('articulosm/' + codigo + '/actprecios.json', {
        pcostarifa: pcostarifa,
        pcos: pcos,
        porcdesc1: porcdesc1,
        porcdesc2: porcdesc2,
        porcpvp: porcpvp,
        pvp: pvp}
        ).then(function(response) {
            return response;
        })
}

export function addmovimientoalmacen(codigo, tipo, movimiento) {
    const token = document.querySelector('meta[name="csrf-token"]').content;
    axios.defaults.headers.common['X-CSRF-Token'] = token;
    axios.defaults.headers.common['Accept'] = 'application/json';

    // return axios.post('articulosm/' + codigo + '/addmovimientoalmacen.json', {
    return axios.post('addmovimientoalmacen.json', {
        tipo: tipo, movimiento: movimiento}).then(function(response) {
            return response;
        })
}

export function delmovimientoalmacen(codigo, fecha, movimiento) {
    const token = document.querySelector('meta[name="csrf-token"]').content;
    axios.defaults.headers.common['X-CSRF-Token'] = token;
    axios.defaults.headers.common['Accept'] = 'application/json';

    console.log('--- API: delmovimientoalmacen ---', codigo);

    return axios.post('delmovimientoalmacen.json', {
        fecha: fecha, movimiento: movimiento}).then(function(response) {
            return response;
        })
}

// export function getmovalmacen(codigo) {
//     // return axios.get('/articulosm.json?numero=' + codigo + '&familia=' + familia).then(function (response) {
//     //     return response.data;
//     // })

// }


// // Busqueda articulos
// export function buscaarticuloscodigo(codigo) {
//     return axios.get('/articulos/busca_codigo.json?filtro=' + codigo).then(function (response) {
//         return response.data;
//     })
// }

// export function buscaarticulosdescripcion(descripcion) {
//     return axios.get('/articulos/busca_descripcion.json?filtro=' + descripcion).then(function (response) {
//         return response.data;
//     })
// }

// export function buscaarticulosmodelo(modelo) {
//     return axios.get('/articulos/busca_modelo.json?filtro=' + modelo).then(function (response) {
//         return response.data;
//     })
// }

// export function buscaarticulosfamilia(familia) {
//     return axios.get('/articulos/busca_familia.json?filtro=' + familia).then(function (response) {
//         return response.data;
//     })
// }

// Busqueda articulosm
export function buscaarticulosmcodigo(codigo) {
    return axios.get('/articulosm/busca_codigo.json?filtro=' + codigo).then(function (response) {
        return response.data;
    })
}

export function buscaarticulosmdescripcion(descripcion) {
    return axios.get('/articulosm/busca_descripcion.json?filtro=' + descripcion).then(function (response) {
        return response.data;
    })
}

export function buscaarticulosmmodelo(modelo) {
    return axios.get('/articulosm/busca_modelo.json?filtro=' + modelo).then(function (response) {
        return response.data;
    })
}

export function buscaarticulosmfamilia(familia) {
    return axios.get('/articulosm/busca_familia.json?filtro=' + familia).then(function (response) {
        return response.data;
    })
}

// Busqueda clientes
// export function buscaclientesnombre(nombre) {
//     return axios.get('/clientes/busca_nombre.json?nombre=' + nombre).then(function (response) {
//         return response.data;
//     })
// }
export function buscaclientesnombre(nombre, sql = false) {
    if (sql) {
        return axios.get('/clientesm/busca_nombre.json?nombre=' + nombre).then(function (response) {
            return response.data;
        })
    } else {
        return axios.get('/clientes/busca_nombre.json?nombre=' + nombre).then(function (response) {
            return response.data;
        })
    }
}

export function buscaclientesnombreexacto(nombre) {
    return axios.get('/clientesm/busca_nombre.json?exacto=1&nombre=' + nombre).then(function (response) {
        return response.data;
    })
}

// export function buscaclientesdni(dni) {
//     return axios.get('/clientes/busca_dni.json?dni=' + dni).then(function (response) {
//         return response.data;
//     })
// }
export function buscaclientesdni(dni, sql = false) {
    if (sql) {
        return axios.get('/clientesm/busca_dni.json?dni=' + dni).then(function (response) {
            return response.data;
        })
    } else {
        return axios.get('/clientes/busca_dni.json?dni=' + dni).then(function (response) {
            return response.data;
        })
    }
}

// export function buscaclientesid(id) {
//     return axios.get('/clientes/' + id + '.json').then(function (response) {
//         return response.data;
//     }).catch(error => console.log(error))
// }

export function buscaclientesid(id, sql = false) {
    if (sql) {
        return axios.get('/clientesm/' + id + '.json').then(function (response) {
            return response.data;
        }).catch(error => console.log(error))
    } else {
        return axios.get('/clientes/' + id + '.json').then(function (response) {
            return response.data;
        }).catch(error => console.log(error))
    }
}

export function logout() {
    return axios.delete('/usuarios/logout').then(function (response) {
        return response.data;
    })
}

// Busqueda clientesm
export function buscaclientesmnombre(nombre) {
    return axios.get('/clientesm/busca_nombre.json?nombre=' + nombre).then(function (response) {
        return response.data;
    })
}

export function buscaclientesmdni(dni) {
    return axios.get('/clientesm/busca_dni.json?dni=' + dni).then(function (response) {
        return response.data;
    })
}

export function buscaclientesmid(id) {
    return axios.get('/clientesm/' + id + '.json').then(function (response) {
        return response.data;
    }).catch(error => console.log(error))
}

export function unificaclientes(principal, lista) {
    return axios.post('/clientesm/unifica.json', {
        lista: lista,
        principal: principal}
        ).then(function(response) {
            return response.data;
        })
}

// Busqueda presupuestos
export function buscapresupuestoid(id, sql = false) {
    if (sql) {
        return axios.get('/presupuestosm/' + id + '.json').then(function (response) {
            return response.data;
        })
    } else {
        return axios.get('/presupuestos/' + id + '.json').then(function (response) {
            return response.data;
        })
    }
}

export function buscapresupuesto(numero, destino = 'f') {
    return axios.get('/presupuestosm/busca_numero.json?numero=' + numero + '&destino=' + destino).then(function (response) {
        return response.data;
    })
}

export function buscapptocliente(cliente, sql = false) {
    if (sql) {
        return axios.get('/presupuestosm/busca_cliente.json?cliente=' + cliente).then(function (response) {
            return response.data;
        })
    } else {
        return axios.get('/presupuestos/busca_cliente.json?cliente=' + cliente).then(function (response) {
            return response.data;
        })
    }
}

export function busquedapptos(cadena, sql = false) {
    // return axios.post('/presupuestos/busqueda.json', {datos: cadena}).then(function (response) {
    if (sql) {
        return axios.get('/presupuestosm/busqueda.json?datos=' + cadena).then(function (response) {
            return response.data;
        })
    } else {
        return axios.get('/presupuestos/busqueda.json?datos=' + cadena).then(function (response) {
            return response.data;
        })
    }
}

export function busquedapptosgeneral(cadena, sql = false) {
    if (sql) {
        return axios.get('/presupuestosm/busquedageneral.json?datos=' + cadena).then(function (response) {
            return response.data;
        })
    } else {
        return axios.get('/presupuestos/busquedageneral.json?datos=' + cadena).then(function (response) {
            return response.data;
        })
    }
}

// export function buscapresupuestoid(id) {
//     return axios.get('/presupuestos/' + id + '.json').then(function (response) {
//         return response.data;
//     })
// }

// export function buscapresupuesto(numero) {
//     return axios.get('/presupuestos/busca_numero.json?numero=' + numero).then(function (response) {
//         return response.data;
//     })
// }

// export function buscapptocliente(cliente) {
//     return axios.get('/presupuestos/busca_cliente.json?cliente=' + cliente).then(function (response) {
//         return response.data;
//     })
// }

// export function busquedapptos(cadena) {
//     // return axios.post('/presupuestos/busqueda.json', {datos: cadena}).then(function (response) {
//     return axios.get('/presupuestos/busqueda.json?datos=' + cadena).then(function (response) {
//         return response.data;
//     })
// }

// export function busquedapptosgeneral(cadena) {
//     return axios.get('/presupuestos/busquedageneral.json?datos=' + cadena).then(function (response) {
//         return response.data;
//     })
// }

// Busqueda presupuestosm
export function buscapresupuestomid(id) {
    return axios.get('/presupuestosm/' + id + '.json').then(function (response) {
        return response.data;
    })
}

export function buscapresupuestom(numero) {
    return axios.get('/presupuestosm/busca_numero.json?numero=' + numero).then(function (response) {
        return response.data;
    })
}

export function buscapptoclientem(cliente) {
    return axios.get('/presupuestosm/busca_cliente.json?cliente=' + cliente).then(function (response) {
        return response.data;
    })
}

export function busquedapptosm(cadena) {
    // return axios.post('/presupuestos/busqueda.json', {datos: cadena}).then(function (response) {
    return axios.get('/presupuestosm/busqueda.json?datos=' + cadena).then(function (response) {
        return response.data;
    })
}

export function busquedapptosgeneralm(cadena) {
    return axios.get('/presupuestosm/busquedageneral.json?datos=' + cadena).then(function (response) {
        return response.data;
    })
}

export function buscapresupuestoclientenombre(cliente, destino = 'f') {
    return axios.get('/presupuestosm/busca_cliente_nombre.json?cliente=' + cliente + '&destino=' + destino).then(function (response) {
        return response.data;
    })
}

// export function buscapresupuestoclienteparafactura(cliente, sql = false) {
//     if (sql) {
//         return axios.get('/presupuestosm/busca_cliente_factura.json?cliente=' + cliente).then(function (response) {
//             return response.data;
//         })
//     } else {
//         return axios.get('/presupuestos/busca_cliente_factura.json?cliente=' + cliente).then(function (response) {
//             return response.data;
//         })
//     }
// }

// export function buscapresupuestoclienteparaalbaran(cliente) {
//     return axios.get('/presupuestosm/busca_cliente_albaran.json?cliente=' + cliente).then(function (response) {
//         return response.data;
//     })
// }

export function buscapresupuestoclienteid(cliente, destino = 'f') {
    return axios.get('/presupuestosm/busca_cliente_id.json?cliente=' + cliente + '&destino=' + destino).then(function (response) {
        return response.data;
    })
}

export function buscapptoclientefiltro(cliente, filtro = '', sql = false) {
    if (sql) {
        return axios.get('/presupuestosm/busca_cliente_filtro.json?cliente=' + cliente + '&texto=' + filtro).then(function (response) {
            return response.data;
        })
    } else {
        return axios.get('/presupuestos/busca_cliente.json?cliente=' + cliente).then(function (response) {
            return response.data;
        })
    }
}

// Busqueda albaranes
export function buscaalbaranid(id, sql = false) {
    if (sql) {
        return axios.get('/albaranesm/' + id + '.json').then(function (response) {
            return response.data;
        })
    } else {
        return axios.get('/albaranes/' + id + '.json').then(function (response) {
            return response.data;
        })
    }
}

export function buscaalbaran(numero, sql = false) {
    if (sql) {
        return axios.get('/albaranesm/busca_numero.json?numero=' + numero).then(function (response) {
            return response.data;
        })        
    } else {
        return axios.get('/albaranes/busca_numero.json?numero=' + numero).then(function (response) {
            return response.data;
        })
    }
}

export function buscaalbarancliente(cliente, sql = false) {
    if (sql) {
        return axios.get('/albaranesm/busca_cliente.json?cliente=' + cliente).then(function (response) {
            return response.data;
        })
    } else {
        return axios.get('/albaranes/busca_cliente.json?cliente=' + cliente).then(function (response) {
            return response.data;
        })
    }
}

export function buscaalbaranclientenombre(cliente, incluye_negativa = false, incluye_facturados= false, sql = false) {
    var _texto_inc_negativa = '&incneg=0';
    var _texto_inc_facturados = '&incfac=0';

    if (incluye_negativa) {
        _texto_inc_negativa = '&incneg=1';
    }

    if (incluye_facturados) {
        _texto_inc_facturados = '&incfac=1';
    }

    if (sql) {
        return axios.get('/albaranesm/busca_cliente_nombre.json?cliente=' + cliente + _texto_inc_negativa + _texto_inc_facturados).then(function (response) {
            return response.data;
        })
        // if (incluye_negativa) {
        //     return axios.get('/albaranesm/busca_cliente_nombre.json?cliente=' + cliente + '&incneg=1').then(function (response) {
        //         return response.data;
        //     })
        // } else {
        //     return axios.get('/albaranesm/busca_cliente_nombre.json?cliente=' + cliente).then(function (response) {
        //         return response.data;
        //     })
        // }
    } else {
        return axios.get('/albaranes/busca_cliente_nombre.json?cliente=' + cliente).then(function (response) {
            return response.data;
        })
    }
}

export function buscaalbaranparafactura(numero, sql = false) {
    if (sql) {
        return axios.get('/albaranesm/busca_numero.json?numero=' + numero + '&factura=1').then(function (response) {
            return response.data;
        })        
    } else {
        return axios.get('/albaranes/busca_numero.json?numero=' + numero).then(function (response) {
            return response.data;
        })
    }
}

export function buscaalbaranclienteparafactura(cliente, sql = false) {
    if (sql) {
        return axios.get('/albaranesm/busca_cliente_factura.json?cliente=' + cliente).then(function (response) {
            return response.data;
        })
    } else {
        return axios.get('/albaranes/busca_cliente_factura.json?cliente=' + cliente).then(function (response) {
            return response.data;
        })
    }
}

export function getlineasalbaran(albaran_id) {
    return axios.get('/albaranes/' + albaran_id + '/lineas.json').then(function (response) {
        return response.data;
    })
}

export function borraalbaranm(albaran_id, sql = false) {
    const token = document.querySelector('meta[name="csrf-token"]').content;

    if (sql) {
        return axios.delete('/albaranesm/' + albaran_id + '.json', {headers: {'X-CSRF-TOKEN': token, 'Content-Type': 'application/json'}}).then(function (response) {
            return response.data;
        })
    } else {
    }
}

export function buscaalbaranclientefiltro(cliente, filtro = '', sql = false) {
    if (sql) {
        return axios.get('/albaranesm/busca_cliente_filtro.json?cliente=' + cliente + '&texto=' + filtro).then(function (response) {
            return response.data;
        })
    } else {
        return axios.get('/albaranes/busca_cliente.json?cliente=' + cliente).then(function (response) {
            return response.data;
        })
    }
}

// Busqueda facturas
export function buscafacturaid(id) {
    return axios.get('/facturas/' + id + '.json').then(function (response) {
        return response.data;
    })
}

export function buscafactura(numero) {
    return axios.get('/facturas/busca_numero.json?numero=' + numero).then(function (response) {
        return response.data;
    })
}

export function buscafacturacliente(cliente, sql = false) {
    if (sql) {
        return axios.get('/facturasm/busca_cliente.json?cliente=' + cliente).then(function (response) {
            return response.data;
        })
    } else {
        return axios.get('/facturas/busca_cliente.json?cliente=' + cliente).then(function (response) {
            return response.data;
        })
    }
}

export function buscafacturaclientefiltro(cliente, filtro = '', sql = false) {
    if (sql) {
        return axios.get('/facturasm/busca_cliente_filtro.json?cliente=' + cliente + '&texto=' + filtro).then(function (response) {
            return response.data;
        })
    } else {
        return axios.get('/facturas/busca_cliente.json?cliente=' + cliente).then(function (response) {
            return response.data;
        })
    }
}

export function borrafacturam(factura_id, sql = false) {
    const token = document.querySelector('meta[name="csrf-token"]').content;

    if (sql) {
        return axios.delete('/facturasm/' + factura_id + '.json', {headers: {'X-CSRF-TOKEN': token, 'Content-Type': 'application/json'}}).then(function (response) {
            return response.data;
        })
    } else {

    }
}

export function facturaspendientesanno(anno) {
    return axios.get('/facturasm/pendientes_cobro.json?anno=' + anno).then(function (response) {
        return response.data;
    })
}

export function cobrafechaanterior(factura, fecha) {
    return axios.post('facturasm/cobrafechaanterior.json', {
        factura: factura,
        fecha: fecha}
        ).then(function(response) {
            return response.data;
        })
}

// Busqueda de cajasm
export function buscacajasm(dias, fecha, sql = false) {
    if (fecha == undefined) {
        return axios.get('/cajasm/busca_cajas.json?dias=' + dias).then(function (response) {
            return response.data;
        })
    } else {
        return axios.get('/cajasm/busca_cajas.json?fecha=' + fecha + '&dias=' + dias).then(function (response) {
            return response.data;
        })
    }
}
// Busqueda de cajas
export function buscacajas(dias, fecha) {
    if (fecha == undefined) {
        return axios.get('/cajas/busca_cajas.json?dias=' + dias).then(function (response) {
            return response.data;
        })
    } else {
        return axios.get('/cajas/busca_cajas.json?fecha=' + fecha + '&dias=' + dias).then(function (response) {
            return response.data;
        })
    }
}

// Busqueda lineascaja
export function buscalineascaja(caja) {
    return axios.get('/lineascaja/busca_caja.json?caja=' + caja).then(function (response) {
        return response.data;
    })
}

// Busqueda lineascaja
export function buscalineascajam(fecha, nro, inifin = false) {
    return axios.get('/cajasm/busca_fecha.json?fecha=' + fecha + '&inifin=' + inifin + '&numero=' + nro).then(function (response) {
        return response.data;
    })
}

// Busqueda documentos
export function buscadocumento(tipo, numero) {
    var _cadena = "/" + tipo + "/busca_numero.json?numero=" + numero;
    // console.log(_cadena);
    return axios.get(_cadena).then(function (response) {
        return response.data;
    })
}

// Recupera enlaces
export function recuperaenlaces(es, tipo) {
    return axios.get("/enlacesm/enlaces.json?es=" + es + "&tipo=" + tipo).then(function (response) {
        return response.data;
    })
}

// Recupera bancos
export function recuperabancos() {
    return axios.get("/bancos.json").then(function (response) {
        return response.data;
    })
}

// Cajam
export function anotamovimientocaja(dh, cm, importe, fecha, cliente, nombre, base = 0, porciva = 0, iva = 0, documento = 0) {
    return axios.post('cajasm/anotamovimiento.json', {
        dh: dh,
        cm: cm,
        importe: importe,
        codcli: cliente,
        nomcli: nombre, 
        fecha: fecha.toISOString().slice(0,10),
        base: base,
        porciva: porciva,
        iva: iva,
        documento: documento}
        ).then(function(response) {
            return response.data;
        })
}

export function borramovimientocaja(id, documento = 0) {
    return axios.post('cajasm/borramovimiento.json', {
        id: id,
        documento: documento}
        ).then(function(response) {
            return response.data;
        })
}

// Proveedores
export function buscaproveedoresid(id, sql = false) {
    if (sql) {
        return axios.get('/proveedoresm/' + id + '.json').then(function (response) {
            return response.data;
        }).catch(error => console.log(error))
    } else {
        return axios.get('/proveedores/' + id + '.json').then(function (response) {
            return response.data;
        }).catch(error => console.log(error))
    }
}

export function buscaproveedoresnombre(nombre, sql = false) {
    if (sql) {
        return axios.get('/proveedoresm/busca_nombre.json?nombre=' + nombre).then(function (response) {
            return response.data;
        })
    } else {
        return axios.get('/proveedores/busca_nombre.json?nombre=' + nombre).then(function (response) {
            return response.data;
        })
    }
}

export function buscaproveedoresdni(dni, sql = false) {
    if (sql) {
        return axios.get('/proveedoresm/busca_dni.json?dni=' + dni).then(function (response) {
            return response.data;
        })
    } else {
        return axios.get('/proveedores/busca_dni.json?dni=' + dni).then(function (response) {
            return response.data;
        })
    }
}

// Listadocumentos
export function getFacturas(numero = '') {
    // return axios.get('/facturasm.json?numero' + numero).then(function (response) {
    return axios.get('/facturasm/getfacturas.json?numero=' + numero).then(function (response) {
        return response.data;
    })
}

export function getFacturasb(numero = '') {
    // return axios.get('/facturasm.json?numero' + numero).then(function (response) {
    return axios.get('/facturasbm/getfacturas.json?numero=' + numero).then(function (response) {
        return response.data;
    })
}



